.screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0; }

.wpcf7-form {
  input, textarea {
    border: 1px solid #ccc;
    padding: 5px 10px;
    @extend .transition;
    width: 100%;
    &:hover, &:focus {
      border-color: #242424;
      outline: none; } }
  .btn {
    border: none; }
  p {
    position: relative; }
  .wpcf7-form-control-wrap {
    position: relative;
    display: block;
    overflow: hidden; }
  label {
    margin-bottom: 0.25rem;
    display: inline-block;
    font-size: 1.125rem;
    span {
      font-size: 0.75rem; } }
  .btn {
    padding: 0.5rem 1rem 0.5rem 1rem; } }

.ajax-loader {
  position: absolute;
  left: 50%;
  top: 8px;
  width: 24px;
  margin-left: -60px; }

span.wpcf7-not-valid-tip {
  transition: all 0.4s;
  color: $secondary-color;
  position: absolute;
  right: 0.5rem;
  bottom: 0;
  z-index: 1;
  height: 34px;
  line-height: 34px;
  font-size: 0.875rem;
  &.remove {
    height: 0;
    opacity: 0;
    visibility: hidden; } }

.wpcf7-response-output {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 2rem;
  background: $secondary-color-darken;
  color: #fff;
  font-weight: 600;
  z-index: 9;
  border-top: 1px solid rgba(0,0,0,0.1);
  &.wpcf7-mail-sent-ok {
    background: #0fa20f; } }
