.header_container {
	display: flex;
	justify-content: space-between;
	align-items: center; }

.hamburger-icon {
	width: 40px;
	height: 30px;
	position: relative;
	margin: 0 auto;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	display: block;
	z-index: 9999;
	@media screen and (min-width: 800px) {
		display: none; }
	span {
		display: block;
		position: absolute;
		height: 6px;
		width: 100%;
		background: $primary-color;
		border-radius: 6px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
		&:nth-child(1) {
			top: 0px; }
		&:nth-child(2),
		&:nth-child(3) {
			top: 12px; }
		&:nth-child(4) {
			top: 24px; } }
	&.open {
		span {
			&:nth-child(1) {
				top: 12px;
				width: 0%;
				left: 50%; }
			&:nth-child(2) {
				transform: rotate(45deg); }
			&:nth-child(3) {
				transform: rotate(-45deg); }
			&:nth-child(4) {
				top: 12px;
				width: 0%;
				left: 50%; } } } }


.main-navigation {
	@extend .transition;
	margin: 0;
	list-style: none;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media screen and (max-width: 799px) {
		&.open {
			visibility: visible;
			opacity: 1;
			li {
				transform: translateY(0px);
				opacity: 1;
				@for $i from 1 through 9 {
					&:nth-child(#{$i}) {
						transition-delay: #{$i * 0.15}s; } } } } }
	@media screen and (min-width: 800px) {
		display: inline-block;
		visibility: visible;
		opacity: 1;
		position: relative;
		z-index: 1;
		background: none; }
	li {
		display: block;
		text-align: center;
		margin-bottom: .25rem;
		transform: translateY(-5px);
		opacity: 0;
		transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		&:last-child {
			margin-bottom: 0; }
		@media screen and (min-width: 800px) {
			transition-delay: inherit !important;
			display: inline-block;
			text-align: inherit;
			margin-bottom: 0;
			transform: none !important;
			opacity: 1;
			transition: none !important; }
		@media screen and (max-width: 799px) {
			margin-left: 0 !important; } } }
