@mixin bg-sprite($x, $y) {
  background: asset-url('sprite.png') no-repeat;
  background-position: $x $y;
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    & {
      background-size: 275px 1250px;
      background-image:  asset-url('sprite@2x.png');
 } } }      // @include bg-sprite(200px, 100px)


// Set Height and width
@mixin size($size) {
  $height: nth($size, 1);
  $width: $height;
  @if length($size) > 1 {
    $height: nth($size, 2); }
  @if $height == auto or (type-of($height) == number and not unitless($height)) {
    height: $height; }
  @if $width == auto or (type-of($height) == number and not unitless($width)) {
    width: $width; } }
// @include size(30px 70px) //width: 30px; height: 70px
