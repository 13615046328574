$primary-color: #02123c;
$secondary-color: #e30a18;
$secondary-color-darken: #b10813;

@import './_helpers';
@import './_normalize';
@import './_foundation';
@import './_lightslider';
@import './_cf7';
@import './_hamburger-icon';


/* Basics */

body {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif; }

h1, h2, h3, h4, h5, h6, {
	font-weight: 600; }

h1 {}


h2 {}


h3 {}

.title {
	color: $secondary-color; }

.sub-title {
	color: #828282;
	font-size: 1.6rem;
	margin: -1rem 0 2rem 0; }

a {
	@extend .transition;
	&:focus {
		outline: none; } }

.strong {
	font-weight: 600; }

.center {
	text-align: center; }

.left {
	float: left; }
.right {
	float: right; }

.transition {
	transition: all 200ms ease-out; }

.separator {
	hr {
		margin: 0; } }

.read-more {
	i {
		@extend .transition;
		margin-left: 0.5rem; }
	&:hover, &:focus {
		i {
			margin-left: 0.75rem; } } }


/* Structure */

.header {
	margin: 0; }

.logo-container {
	float: left;
	margin: 0; }

.logo {
	display: block;
	text-indent: -9999px;
	margin: 0.5rem 0;
	img {
		@include size(250px 90px);
		display: block; } }

.toggle-menu {
	display: none; }

.main-navigation {
	float: right;
	//margin: 3rem 0 0 0
	ul {
		margin-left: 0; }
	li {
		list-style: none;
		padding: 0;
		display: inline-block;
		margin-left: 1.5rem;
		&:first-child {
			margin-left: 0; } }
	a {
		display: inline-block;
		color: #4f4f4f;
		font-weight: 600;
		&:hover, &:focus {
			color: $secondary-color; } }
	.current-post-ancestor,
	.current-menu-item {
		a {
			color: $secondary-color; } } }

.banner {
	margin: auto; }

.banner-container {
	li {
		overflow: hidden;
		@include size(100% 340px);
		position: relative;
		display: flex; }
	img {
		display: block;
		width: 100%;
		position: absolute; }
	.row {
		position: relative;
		margin: auto; }
	.title {
		max-width: 600px;
		color: #fff;
		text-shadow: 1px 1px 10px rgba(0,0,0,0.6);
		margin-bottom: 1rem; }
	.read-more {
		color: #fff;
		background: $secondary-color;
		padding: 0.5rem 1rem 0.5rem 1rem;
		box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
		&:hover, &:focus {
			background: $secondary-color-darken; } } }

.banner-container-little {
	height: 200px;
	overflow: hidden;
	img {
		width: 100%; } }

.section {
	padding: 3rem 0 4rem; }

.footer {
	background: #242424;
	color: #fff;
	padding: 3rem 0; }

.sub-footer {
	background: #000;
	color: #fff;
	font-size: 0.875rem;
	padding: 1rem 0;
	.title {
		display: inline-block; }
	p {
		margin-bottom: 0;
		font-size: 12px; }
	.by {
		color: $secondary-color;
		a {
			&:hover, &:focus {
				color: #fff; } } } }

/* page d'accueil */


.home {
	#main {
		.section:first-child {
			padding-bottom: 0; } } }
.archive {
	#main {
		.title {
			margin-bottom: 1rem; } }
	.branding {
		margin-bottom: 2rem;
		&:nth-child(3n-2) {
			clear: left; }
		&:last-child {
			float: left; } } }

.activity-area {
	margin-bottom: 4rem;
	.read-more {
		float: right; }
	&:last-child {
		float: left; } }

.activity-area-header {
	position: relative;
	margin-bottom: 1.5rem;
	.title {
		background: $secondary-color;
		color: #fff;
		padding: 0.25rem 1rem;
		margin-bottom: 0;
		display: inline-block;
		font-size: 1.25rem;
		position: absolute;
		bottom: 0.5rem;
		left: 0.5rem;
		z-index: 1; } }

.activity-area-img {
	overflow: hidden;
	display: block;
	img {
		@extend .transition; }
	&:hover, &:focus {
		img {
			transform: scale(1.03); } } }

.activity-area-intro {
	color: #828282; }

.logos-container {
	li {
		text-align: center; }
	img {
		display: inline-block; } }

.about {
	position: relative;
	p {
		color: #fff; } }
.about-left,
.about-right {
	position: absolute;
	top: 0;
	bottom: 0; }

.about-left {
	left: 0;
	right: 50%;
	background: url('./../img/about-bg.jpg') no-repeat;
	background-size: cover; }
.about-right {
	left: 50%;
	right: 0;
	background: #1c202a; }

.products {
	position: relative;
	padding-bottom: 2.5rem;
	overflow: hidden;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(255,255,255,0.9);
		z-index: 9; } }

.product {
	margin-bottom: 1.5rem;
	padding-top: 15px;
	@extend .transition;
	&:hover {
		box-shadow: 0 0 20px 0 rgba(0,0,0,0.15); } }

.product-img {
	img {
		border: 1px solid #e8e8e8;
		@extend .transition; }
	&:hover, &:focus {
		img {
			transform: scale(1.03);
			border-color: #c3c3c3; } } }

.product-title {
	a {
		color: #4f4f4f;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;
		&:hover, &:focus {
			color: $secondary-color; } } }

.product-description {
	margin-bottom: 1rem;
	color: #828282;
	line-height: 1.2em; }


.product-footer {}

.product-add-cart, .poduct-price {
	display: inline-block; }

.product-add-cart {
	padding: 0.25rem 1rem;
	border: 1px solid $secondary-color;
	color: $secondary-color;
	font-size: 0.875rem;
	line-height: 22px;
	position: relative;
	span, i {
		display: inline-block;
		vertical-align: middle; }
	i {
		@extend .transition;
		color: $secondary-color-darken;
		position: absolute;
		opacity: 0;
		left: 13px;
		line-height: 24px; }
	span {}
	&:hover, &:focus {
		padding-left: 1.8rem;
		border-color: $secondary-color-darken;
		i {
			opacity: 1;
			left: 10px; } } }

.poduct-price {
	background: $secondary-color;
	color: #fff;
	padding: 0.25rem 0.75rem;
	font-weight: 800; }

.grey {
	background: #eee;
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(255,255,255,0.9);
		z-index: 9; } }

.product-title {}

.branding {
	.sub-title {
		margin: 0 0 0.5rem 0; }
	.read-more {
		float: right; } }


.branding-img {
	overflow: hidden;
	display: block;
	margin-bottom: 1rem;
	img {
		@extend .transition; }
	&:hover {
		img {
			transform: scale(1.03); } } }

.branding-title {
	line-height: 1.6rem;
	a {
		color: #4f4f4f;
		font-size: 1.6rem;
		&:hover, &:focus {
			color: $secondary-color; } } }
.branding-intro {
	color: #828282;
	p {
		margin-bottom: 0.5rem;
		&:first-child {
			font-weight: 600;
			color: #4f4f4f; } } }

.footer {
	.title {
		font-size: 1.6rem;
		font-weight: 600; } }
.coordonnees-container {
	.title {
		padding-left: 0;
		margin-bottom: 1rem; }
	p {
		position: relative;
		padding-left: 25px;
		margin-bottom: 0.5rem; }
	i {
		position: absolute;
		left: 0;
		top: 5px; } }

#map {
	height: 200px; }

.btn {
	background: $secondary-color;
	color: #ffffff;
	border: none;
	@extend .transition;
	&:hover, &:focus {
		background: $secondary-color-darken; } }

.newsletter-container {
	position: relative;
	float: right;
	.title, .mc4wp-form {
		display: inline-block;
		vertical-align: top; }
	.title {
		margin-right: 0.5rem;
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: 600;
		color: #828282; }
	input, button {
		border: none;
		display: inline-block;
		line-height: 1.25rem;
		padding: 5px 10px;
		border: 1px solid #ffffff;
		transition: 0.2s;
		&:focus, &:hover {
			outline: none;
			border-color: $secondary-color; } }
	input {
		min-width: 220px; }
	.btn {
		margin-left: -4px;
		border-color: $secondary-color;
		&:hover, &:focus {
			border-color: $secondary-color-darken; }
		span {
			display: none; } } }

.widget {
	padding: 20px;
	border: 1px solid #d7d7d7;
	ul {
		margin: 0; }
	li {
		list-style: none;
		&.current-menu-item a {
			color: $secondary-color;
			&:before {
				content: "●";
				margin: 0 5px 0 -15px; } } }
	a {
		color: #0a0a0a;
		display: inline-block;
		padding: 6px 20px;
		&:hover, &:focus {
			color: $secondary-color; } } }

.widgettitle {
	font-weight: 600;
	margin-bottom: 10px;
	color: #4f4f4f;
	font-size: 1.6rem;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }

@media screen and (min-width: 64em) {
	.widget {
		margin-top: 53px; } }

.alignleft {
	float: left;
	margin-bottom: 10px;
	margin-right: 20px; }

.alignright {
	float: right;
	margin-bottom: 10px;
	margin-left: 20px; }

.aligncenter {
	display: block;
	margin: 10px auto; }

.alignnone {
	display: block; }

.gdpr {
	width: 25px;
	float: left;
	.wpcf7-not-valid-tip {
		display: none !important; } }
